<template>
  <div class="video-wrap">
    <pageTitle pageTitle="音视频">
      <span class="video-text">音视频中的文件内容应用于暖场视频、点播、定时直播和插播文件</span>
      <div slot="content">
        1.上传单个文件最大5G
        <br />
        2.上传视频格式支持RMVB、MP4、AVI、WMV、MKV、FLV、MOV；上传音频格式支持MP3
        <br />
        3.上传的视频，不支持剪辑和下载
        <br />
      </div>
    </pageTitle>
    <div class="head-operat" v-show="total || isSearch">
      <vh-button size="medium" type="primary" round class="length104 head-btn set-upload">
        上传
        <input
          ref="uploads"
          class="set-input"
          type="file"
          @change="tirggerFile($event)"
          accept=".mp4,.mp3,.rmvb,.avi,.mkv,.flv,.mov,.mav,.wmv"
        />
      </vh-button>
      <vh-button
        size="medium"
        round
        plain
        class="transparent-btn"
        @click="allDelete(null)"
        :disabled="!checkedList.length"
      >
        批量删除
      </vh-button>
      <vh-input
        round
        class="search-tag"
        placeholder="请输入音视频名称"
        v-model="keyword"
        clearable
        @clear="searchTableList"
        @keyup.enter.native="searchTableList"
      >
        <i class="vh-icon-search vh-input__icon" slot="prefix" @click="searchTableList"></i>
      </vh-input>
    </div>
    <div class="video-list" v-if="total || isSearch">
      <vh-table
        @cell-mouse-enter="handleCellMouseEnter"
        @cell-mouse-leave="handleCellMouseLeave"
        :data="tableData"
        @selection-change="changeTableCheckbox"
        :header-cell-style="{ background: '#f7f7f7', color: '#666', height: '56px' }"
      >
        <vh-table-column type="selection" width="60" align="left" :selectable="checkSelectable" />
        <vh-table-column label="音视频名称" min-width="200">
          <template slot-scope="scope">
            <vh-tooltip
              placement="top"
              :disabled="!isTextOverflow"
              :content="scope.row.video_name == '' ? '- -' : scope.row.video_name"
            >
              <div class="videoName custom-tooltip-content">
                <i
                  class="iconfont-v3 saasyinpinwenjian"
                  v-if="scope.row.msg_url == '.mp3' || scope.row.msg_url == '.mav'"
                ></i>
                <i class="iconfont-v3 saasshipinwenjian" v-else></i>
                {{ scope.row.video_name || '- -' }}
              </div>
            </vh-tooltip>
          </template>
        </vh-table-column>
        <vh-table-column width="160" prop="created_at" label="上传时间"></vh-table-column>
        <vh-table-column width="100" prop="duration" label="时长"></vh-table-column>
        <vh-table-column width="110" prop="transcode_status_text" label="进度">
          <template slot-scope="{ row }">
            <div>
              <p v-if="row.uploadObj">
                <!-- 上传 -->
                <span>{{ row.uploadObj.num == 100 ? '上传已完成' : '文件上传中' }}</span>
                <vh-progress :percentage="row.uploadObj.num"></vh-progress>
              </p>
              <!-- {{scope.row}} -->
              <p v-if="row.transcode_status_text">
                <!-- 列表 -->
                <span
                  class="statusTag"
                  :class="
                    row.transcode_status == 1
                      ? 'success'
                      : row.transcode_status == 0
                      ? 'wating'
                      : 'failer'
                  "
                >
                  <span>{{ row.transcode_status_text }}</span>
                  <vh-progress
                    v-if="row.transcode_status == 0"
                    :percentage="row.percentage"
                  ></vh-progress>
                </span>
              </p>
            </div>
          </template>
        </vh-table-column>
        <vh-table-column width="100" prop="storage" label="转码后大小"></vh-table-column>
        <vh-table-column v-if="showDeleteTime" width="160" label="自动删除时间">
          <template slot-scope="scope">
            {{ scope.row.expire_time | dateFormat('YYYY-MM-DD HH:mm:ss', '无删除期限') }}
          </template>
        </vh-table-column>
        <vh-table-column width="160" label="操作" fixed="right">
          <template slot-scope="scope">
            <vh-button
              type="text"
              @click="preview(scope.row)"
              v-if="scope.row.transcode_status == 1"
            >
              预览
            </vh-button>
            <vh-button
              type="text"
              @click="update(scope.row)"
              v-if="scope.row.transcode_status >= 0 && scope.row.transcode_status != 2"
            >
              编辑
            </vh-button>
            <vh-button type="text" @click="del(scope.row)" v-if="scope.row.transcode_status >= 0">
              删除
            </vh-button>
          </template>
        </vh-table-column>
        <div slot="empty"><noData :nullType="'search'" v-if="!total"></noData></div>
      </vh-table>
      <SPagination
        :total="total"
        :page-size="pageInfo.limit"
        :currentPage="pageInfo.pageNum"
        @current-change="currentChangeHandler"
        @size-change="handleSizeChange"
      ></SPagination>
    </div>
    <div class="no-live" v-else>
      <noData :nullType="'nullData'" :text="'暂未上传音视频'">
        <vh-button type="primary" round class="length106 head-btn set-upload">
          上传
          <input ref="upload" class="set-input" type="file" @change="tirggerFile($event)" />
        </vh-button>
      </noData>
    </div>
    <!-- 预览功能 -->
    <template v-if="showDialog">
      <vh-dialog
        class="vh-saas-dialog"
        :visible.sync="showDialog"
        :before-close="closeBefore"
        width="30%"
        center
        :close-on-click-modal="true"
        :close-on-press-escape="false"
      >
        <video-preview ref="videoPreview" :videoParam="videoParam"></video-preview>
      </vh-dialog>
    </template>
    <!-- 编辑功能 -->
    <template v-if="editShowDialog">
      <vh-dialog
        top="4vh"
        title="编辑"
        :visible.sync="editShowDialog"
        width="640px"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
      >
        <vh-form label-width="50px" :model="formData" ref="advertisementForm">
          <vh-form-item required label="标题">
            <vh-input
              v-model="formData.filename"
              :maxlength="100"
              show-word-limit
              placeholder="请输入标题"
              autocomplete="off"
            ></vh-input>
          </vh-form-item>
          <vh-form-item label="封面">
            <div class="img-box">
              <upload
                ref="video_uploader"
                v-model="formData.img_url"
                :domain_url="domain_url"
                :saveData="saveImageData"
                :on-success="uploadAdvSuccess"
                :on-change="handleUploadChange"
                :heightImg="120"
                @delete="deleteImg"
                :auto-upload="false"
              >
                <div slot="tip">
                  <p>小于4M，支持jpg、gif、png、bmp</p>
                </div>
              </upload>
            </div>
          </vh-form-item>
          <vh-form-item label="简介" prop="intro">
            <v-editor
              :toolbar="'fontsizeselect fontselect forecolor backcolor bold italic underline anchor | alignleft aligncenter alignright alignjustify | table | \link | image | fullscreen'"
              :height="200"
              placeholder="请输入简介"
              save-type="special"
              :isReturn="true"
              :maxWord="10000"
              @returnChange="sendData"
              ref="unitImgTxtEditor"
              v-model="formData.intro"
            ></v-editor>
          </vh-form-item>
        </vh-form>
        <span slot="footer" class="dialog-footer create-footer">
          <vh-button type="info" size="medium" @click="close" plain round>取消</vh-button>
          <vh-button
            type="primary"
            size="medium"
            v-preventReClick
            :disabled="!formData.filename"
            @click="sureMaterialVideo"
            round
          >
            确定
          </vh-button>
        </span>
        <!-- <div class="edit-contianer">
          <div class="main-edit">
            <vh-input
              v-model="videoName"
              v-clearEmoij
              :maxlength="100"
              autocomplete="off"
              show-word-limit
              type="text"
              style="width: 356px"
              placeholder="请输入名称"
            ></vh-input>
            <p v-show="errorText">请输入正确的格式文件</p>
          </div>
          <div class="dialog-footer">
            <vh-button
              size="medium"
              type="primary"
              round
              class="length104"
              @click="sureMaterialVideo"
              v-preventReClick
            >
              确定
            </vh-button>
            <vh-button size="medium" round plain class="length104" @click="editShowDialog = false">
              取消
            </vh-button>
          </div>
        </div> -->
      </vh-dialog>
    </template>
    <!-- 裁剪组件 -->
    <cropper
      ref="adviseCropper"
      :currentMode="currentMode"
      @cropComplete="cropComplete"
      @resetUpload="resetChooseFile"
      :ratio="400 / 225"
    ></cropper>
  </div>
</template>

<script>
  import PageTitle from '@/components/PageTitle';
  import VideoPreview from './VideoPreview/index.vue';
  import { sessionOrLocal } from '@/utils/utils';
  import noData from '@/views/PlatformModule/Error/nullPage';
  import { formateSeconds } from '@/utils/general';
  import tableCellTooltip from '@/components/TableList/mixins/tableCellTooltip';
  import { sizeTostr } from '@/utils/filter';
  import upload from '@/components/Upload/main';
  import cropper from '@/components/Cropper/index';
  import VEditor from '@/components/Tinymce';
  import { buildOssUploadProcessData, parseQueryString, buildUrlByMode } from '@/utils/utils';
  export default {
    name: 'video.vue',
    mixins: [tableCellTooltip],
    data() {
      return {
        total: 0,
        // 预览
        showDialog: false,
        isSearch: false,
        isLeave: false,
        lowName: '',
        keyword: '',
        loading: true,
        editShowDialog: false,
        pageInfo: {
          pageNum: 1,
          pos: 0,
          limit: 10
        },
        videoParam: {},
        // 表格
        tableData: [],
        checkedList: [],
        UploadSDK: null,
        uploadId: -1,
        uploadList: [],
        vm: null,

        formData: {
          img_url: '',
          filename: '',
          intro: ''
        },
        upload_process_data: '', //上传组件的附加数据
        currentMode: 1, //当前的正在使用的裁切模式
        showDeleteTime: false
      };
    },
    components: {
      PageTitle,
      VideoPreview,
      noData,
      upload,
      cropper,
      VEditor
    },
    computed: {
      domain_url() {
        if (!this.formData.img_url) return '';
        return buildUrlByMode(this.formData.img_url, this.currentMode);
      },
      // 保存的上传图片的附加数据
      saveImageData() {
        let imageData = {
          path: 'webinars/spread-imgs',
          type: 'image'
        };
        if (this.upload_process_data) {
          imageData.process = this.upload_process_data;
        }
        return imageData;
      }
    },
    created() {
      // 初始化聊天SDK
      // this.initChat();
      this.userId = JSON.parse(sessionOrLocal.get('userId'));
      this.getVideoAppid();
      this.getTableList();
      this.loading = false;

      const permission = JSON.parse(sessionOrLocal.get('SAAS_VS_PES', 'localStorage'));
      // 有直播回放同步至媒资库权限，则显示自动删除时间列
      this.showDeleteTime = !!permission['live_to_video'];
    },
    mounted() {
      this.$EventBus.$on('sign_trans_code', res => {
        //不是自己的消息不处理
        if (this.userId != res.user_id) {
          return;
        }
        // 转码状态
        console.log(res, '监听到sign_trans_code未读消息提示事件');
        this.tableData.map((item, index) => {
          // 0 转码中 1转码成功 2转码失败
          if (res.record_id == item.id) {
            if (res.status == 1) {
              item.transcode_status = 1;
              item.percentage = undefined;
              item.duration = formateSeconds(res.duration);
              item.transcode_status_text = '转码成功';
              item.storage = res.storage ? sizeTostr(res.storage * 1024) : '--';
            } else if (res.status == 0) {
              item.transcode_status = 0;
              item.percentage = res.progress;
              item.transcode_status_text = '转码中';
            } else {
              item.percentage = undefined;
              item.transcode_status = 2;
              item.duration = '——';
              item.transcode_status_text = '转码失败';
            }
            this.$set(this.tableData, index, Object.assign({}, item));
          }
        });
        // this.getTableList();
      });
    },
    methods: {
      // 处理图片参数
      handlerImageInfo(url) {
        this.formData.img_url = url;
        let obj = parseQueryString(url);
        if (obj.mode) {
          this.currentMode = obj.mode;
        }
      },
      close() {
        this.editShowDialog = false;
      },
      // 删除
      deleteImg() {
        this.formData.img_url = '';
      },
      resetChooseFile() {
        this.$refs.video_uploader.resetChooseFile();
      },
      cropComplete(cropperData, url, mode) {
        this.currentMode = mode;
        if (cropperData) {
          this.upload_process_data = buildOssUploadProcessData({
            cropperData: cropperData
            // blurryDegree: blurryDegree,
            // lightDegree: lightDegree
          });
        } else {
          this.upload_process_data = '';
        }

        this.$nextTick(() => {
          this.$refs.video_uploader.upload();
        });
      },
      /**
       * 图片上传成功回调
       *
       * @param   {[type]}  res   [res description]
       * @param   {[type]}  file  [file description]
       *
       * @return  {[type]}        [return description]
       */
      uploadAdvSuccess(res, file) {
        console.log(res, file);
        if (res.data) {
          //TODO: 处理成功的逻辑
          this.formData.img_url = res.data.domain_url;
        }
      },
      handleUploadChange(file, fileList) {
        if (file.status == 'ready') {
          this.$checkUploadType(file.raw, this, 1, 4, () => {
            let reader = new FileReader();
            reader.readAsDataURL(file.raw);
            reader.onload = e => {
              this.$refs.adviseCropper.showModel(e.target.result);
            };
          });
        }
      },
      sendData(content) {
        this.formData.intro = content;
        console.log(content, '1111111111111111');
      },
      checkSelectable(row) {
        if (row.transcode_status_text) {
          return true;
        } else {
          return false;
        }
      },
      searchTableList() {
        if (this.keyword) {
          this.$vhall_paas_port({
            k: 100524,
            data: {
              business_uid: this.userId,
              user_id: '',
              webinar_id: '',
              refer: '',
              s: '',
              report_extra: {},
              ref_url: '',
              req_url: ''
            }
          });
        }
        this.getTableList('search');
      },
      initPayMessage() {
        // let that = this;
        this.vm = this.$vhMessage({
          showClose: true,
          duration: 0,
          dangerouslyUseHTMLString: true,
          message: '上传过程中请勿关闭或刷新浏览器',
          type: 'warning'
        });
      },
      getTableList(params) {
        // let pageInfo = this.$refs.tableList.pageInfo; //获取分页信息
        if (params == 'search') {
          this.pageInfo.pageNum = 1;
          this.pageInfo.pos = 0;
        }
        let formParams = {
          title: this.keyword,
          get_no_trans: 0,
          user_id: this.userId,
          ...this.pageInfo
        };
        this.isSearch = this.keyword ? true : false;
        this.getList(formParams);
      },
      tirggerFile(event) {
        this.$vhall_paas_port({
          k: 100518,
          data: {
            business_uid: this.userId,
            user_id: '',
            webinar_id: '',
            refer: '',
            s: '',
            report_extra: {},
            ref_url: '',
            req_url: ''
          }
        });
        const typeList = ['rmvb', 'mp4', 'avi', 'wmv', 'mkv', 'flv', 'mov', 'mp3', 'mav'];
        let file = event.target.files[0];
        let beforeName = event.target.files[0].name.toLowerCase();
        let videoArr = beforeName.toLowerCase().split('.');
        console.log(videoArr, '??????????????');
        const videoType = typeList.includes(videoArr[videoArr.length - 1]);
        if (!videoType) {
          this.$vhMessage({
            message: `您上传的文件格式不正确`,
            showClose: true,
            // duration: 0,
            type: 'error',
            customClass: 'zdy-info-box'
          });
          return false;
        }
        if (file.size > 5.3638709e9) {
          this.$vhMessage({
            message: `您上传的文件不能大于5G`,
            showClose: true,
            // duration: 0,
            type: 'error',
            customClass: 'zdy-info-box'
          });
          return false;
        }
        // if(beforeName.indexOf('.mp')==-1){
        //   this.$vhMessage({
        //     type: 'error',
        //     message: '您上传的文件格式不正确'
        //   });
        //   return;
        // }
        // let reg = /^[\u4e00-\u9fa5_a-zA-Z0-9]{0,10}$/;
        // let name = beforeName.split('.m')[0];
        // console.log(name, beforeName,  '22222222222222222222222222');
        let onlyId = this.uploadId--;
        file.id = onlyId;
        // if(!reg.test(name)){
        //   this.$vhMessage({
        //     type: 'warning',
        //     message: '请确认上传的文件是否为中文、英文、数字和下划线组成'
        //   });
        //   return;
        // }
        if (this.vm) {
          this.vm.close();
          this.initPayMessage();
        } else {
          this.initPayMessage();
        }
        let param = {
          create_time: dayjs(file.lastModifiedDate).format('YYYY-MM-DD HH:mm:ss'),
          file_name: beforeName, //后端要求名称带上后缀名  如xxx 改成 xxx.mp4
          duration: '',
          video_name: beforeName,
          msg_url: `.${videoArr[videoArr.length - 1]}`,
          uploadObj: {}, // type：1   上传视频     2创建点播
          id: onlyId
        };
        console.log(param, '33333333333333333');
        this.uploadList.unshift(param);
        this.tableData.unshift(param);
        if (!this.total) {
          this.total = 1;
        }
        this.UploadSDK.upload(
          [file],
          pro => {
            this.tableData.forEach(ele => {
              if (ele.id == file.id) {
                ele.uploadObj = {
                  type: 1, // 上传类型
                  text: '文件正在上传中',
                  num: Math.floor(pro.progress * 100)
                };
              }
            });
          },
          res => {
            if (!this.isLeave) {
              console.log(res, '本地上传成功');
              console.log(res, 11111);
              this.vm.close();
              this.createVod(res.file, param);
            }
          },
          err => {
            console.log(err, '失败');
            this.tableData.shift();
            this.uploadList.shift();
            this.$vhMessage({
              message: `本地上传失败`,
              showClose: true,
              // duration: 0,
              type: 'error',
              customClass: 'zdy-info-box'
            });
          }
        );
      },
      // 每页显示的内容变化
      handleSizeChange(val) {
        this.pageInfo.limit = val;
        this.pageInfo.pageNum = 1;
        this.pageInfo.pos = 0;
        this.getTableList();
      },
      // 页码改变按钮事件
      currentChangeHandler(current) {
        this.pageInfo.pageNum = current;
        this.pageInfo.pos = parseInt((current - 1) * this.pageInfo.limit);
        this.getTableList();
      },
      createVod(_file, param) {
        this.UploadSDK.createDemand(
          { file: _file, fileName: 'name' },
          res => {
            this.$refs.uploads.value = null;
            this.$fetch('createVideo', {
              paas_id: res.recordId,
              user_id: this.userId,
              filename: _file.name
            }).then(res => {
              param.id = res.data.record_id;
              this.$fetch('getmediaInfo', {
                record_id: res.data.record_id
              }).then(result => {
                const index = this.tableData.findIndex(el => {
                  return el.id === res.data.record_id;
                });
                result.data.msg_url = result.data.name.substring(result.data.name.lastIndexOf('.'));
                this.transData(result.data);
                this.$set(this.tableData, index, result.data);
              });

              // this.tableData.splice(0, 1, this.uploadList);
              console.log(this.tableData, this.uploadList, '000000000000000000');
              this.$vhMessage({
                message: `上传视频成功`,
                showClose: true,
                // duration: 0,
                type: 'success',
                customClass: 'zdy-info-box'
              });
              // this.getTableList();
            });
          },
          err => {
            this.tableData.shift();
            this.uploadList.shift();
            this.$vhMessage({
              message: `创建音视频失败`,
              showClose: true,
              // duration: 0,
              type: 'error',
              customClass: 'zdy-info-box'
            });
          }
        );
      },
      initUpload(id, token) {
        let option = {
          appId: id, // appId
          accountId: this.userId, // 第三方用户id
          token: token, // token
          attributeId: this.userId
        };
        window.VhallUpload.createInstance(
          option,
          res => {
            this.UploadSDK = res.interface; // 创建成功
          },
          err => {
            console.warn(err, '上传demo初始化失败');
          }
        );
      },
      getVideoAppid() {
        this.$fetch('getAppid').then(res => {
          this.initUpload(res.data.app_id, res.data.access_token);
        });
      },
      transData(ele) {
        ele.video_name = ele.name;
        ele.msg_url = ele.msg_url && ele.msg_url.toLowerCase();
        // if (!ele.transcode_status) {
        //   ele.uploadObj = {
        //     text: '视频正在转码',
        //     num: Math.floor(1*100)
        //   }
        // }
        switch (ele.transcode_status) {
          case '0':
            ele.transcode_status_text = '转码中';
            ele.duration = '——';
            break;
          case '1':
            ele.transcode_status_text = '转码成功';
            break;
          case '2':
            ele.transcode_status_text = '转码失败';
            ele.duration = '——';
            break;
          case '3':
            ele.transcode_status_text = '转码中';
            ele.duration = '——';
            break;
          default:
            ele.transcode_status_text = '转码中';
            ele.duration = '——';
            break;
        }
      },
      getList(obj) {
        this.$fetch('dataVideoList', this.$params(obj))
          .then(res => {
            if (res.code == 200) {
              this.total = res.data.total;
              // 转码状态:0新增排队中 1转码成功 2转码失败 3转码中
              res.data.list.forEach(ele => {
                this.transData(ele);
              });
              this.tableData = res.data.list;
              // this.checkedList = [];
              // if(this.uploadList.length!=0){
              //   this.tableData =this.uploadList.concat(this.tableData);
              // }
            }
          })
          .finally(() => {
            this.loading = false;
          });
      },
      // 编辑
      update(rows) {
        this.$fetch('videoInfo', {
          video_id: rows.id
        }).then(res => {
          this.editShowDialog = true;
          this.formData.img_url = res.data.img_url;
          this.formData.id = res.data.id;
          this.formData.intro = res.data.intro;
          this.formData.filename = res.data.name;
          this.handlerImageInfo(res.data.img_url);
          this.$vhall_paas_port({
            k: 100522,
            data: {
              business_uid: this.userId,
              user_id: '',
              webinar_id: '',
              refer: '',
              s: '',
              report_extra: {},
              ref_url: '',
              req_url: ''
            }
          });
        });
      },
      sureMaterialVideo() {
        if (!this.formData.filename) {
          return;
        } else {
          let name = `${this.formData.filename}${this.lowName}`;
          this.$fetch('dataVideoupdate', {
            video_id: this.formData.id,
            user_id: this.userId,
            filename: name,
            intro: this.formData.intro,
            img_url: this.domain_url
          }).then(res => {
            // 恢复裁切模式默认全局参数
            this.currentMode = 1;
            if (res.code == 200) {
              this.$vhall_paas_port({
                k: 100521,
                data: {
                  business_uid: this.userId,
                  user_id: '',
                  webinar_id: '',
                  refer: '',
                  s: '',
                  report_extra: {},
                  ref_url: '',
                  req_url: ''
                }
              });
              this.editShowDialog = false;
              this.$vhMessage({
                message: `修改成功`,
                showClose: true,
                // duration: 0,
                type: 'success',
                customClass: 'zdy-info-box'
              });
              this.getTableList();
            }
          });
        }
      },
      confirmDelete(id, index) {
        this.$vhConfirm('删除后将会影响暖场视频的演示和观看，确认删除？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          customClass: 'zdy-message-box',
          lockScroll: false,
          roundButton: true,

          cancelButtonClass: 'zdy-confirm-cancel'
        })
          .then(() => {
            this.$fetch('dataVideoDel', { video_ids: id, user_id: this.userId })
              .then(res => {
                if (res.code == 200) {
                  this.$vhall_paas_port({
                    k: index == 1 ? 100520 : 100519,
                    data: {
                      business_uid: this.userId,
                      user_id: '',
                      webinar_id: '',
                      refer: '',
                      s: '',
                      report_extra: {},
                      ref_url: '',
                      req_url: ''
                    }
                  });
                  this.$refs.uploads.value = null;
                  this.getTableList('search');
                  this.$vhMessage({
                    message: `删除成功`,
                    showClose: true,
                    // duration: 0,
                    type: 'success',
                    customClass: 'zdy-info-box'
                  });
                }
              })
              .catch(res => {
                this.$vhMessage({
                  message: res.msg || `删除失败`,
                  showClose: true,
                  // duration: 0,
                  type: 'error',
                  customClass: 'zdy-info-box'
                });
              });
          })
          .catch(() => {});
      },
      del(rows) {
        this.checkedList = [];
        if (this.audit_status) {
          this.$vhConfirm(
            '该文件已被关联，删除将导致相关文件无法播放且不可恢复，确认删除？',
            '提示',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              roundButton: true,
              customClass: 'zdy-message-box',
              lockScroll: false,

              cancelButtonClass: 'zdy-confirm-cancel'
            }
          )
            .then(() => {
              this.confirmDelete(rows.id, 2);
            })
            .catch(() => {});
        } else {
          this.confirmDelete(rows.id, 2);
        }
      },
      // 批量删除
      allDelete() {
        let id = this.checkedList.join(',');
        this.confirmDelete(id, 1);
      },
      preview(rows) {
        //  this.videoParam 进本信息
        if (rows.transcode_status == 1) {
          this.showDialog = true;
          this.videoParam = rows;
          this.$vhall_paas_port({
            k: 100523,
            data: {
              business_uid: this.userId,
              user_id: '',
              webinar_id: '',
              refer: '',
              s: '',
              report_extra: {},
              ref_url: '',
              req_url: ''
            }
          });
        } else {
          this.$vhMessage.warning('只有转码成功才能查看');
        }
      },
      // operating(val){
      //   let methodsCombin = this.$options.methods;
      //   methodsCombin[val.type](this, val);
      // },
      changeTableCheckbox(item) {
        this.checkedList = item.map(val => val.id);
      },
      uploadSucess(msg) {
        console.log('上传成功', msg);
      },
      closeBefore(done) {
        this.$refs.videoPreview.destroy();
        done();
      }
    },
    beforeDestroy() {
      this.isLeave = true;
      if (this.vm) {
        this.vm.close();
        this.vm = null;
      }
      // if (this.UploadSDK) {
      //   this.UploadSDK.destroy()
      //   this.UploadSDK = null;
      // }
      this.$EventBus.$off('sign_trans_code');
    }
  };
</script>

<style lang="less" scoped>
  .video-list {
    width: 100%;
    .layout--right--main();
    .padding-table-list();
    .min-height();
  }

  .pageBox {
    margin-top: 30px;
  }
  .search-tag {
    float: right;
    width: 220px;
  }
  .videoName {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    .iconfont-v3 {
      font-size: 20px;
      vertical-align: middle;
    }
    .saasyinpinwenjian {
      color: #10d3a8;
      padding-right: 3px;
    }
    .saasshipinwenjian {
      color: #ff733c;
      padding-right: 3px;
    }
  }

  ::v-deep .vh-progress-bar__inner {
    background-color: #14ba6a;
  }
  .statusTag {
    font-size: 14px;
    &::before {
      content: '';
      width: 8px;
      height: 8px;
      border-radius: 50%;
      display: inline-block;
      margin-right: 6px;
    }
    &.wait-progress:before {
      display: none;
    }
    &.wating::before {
      background: #fa9a32;
    }
    &.success::before {
      background: #14ba6a;
    }
    &.failer::before {
      background: #fb3a32;
    }
    .iconContainer {
      padding-left: 10px;
      cursor: pointer;
    }
    /deep/ .saasicon-reset {
      color: #fb3a32;
    }
  }
  .video-wrap {
    height: 100%;
    width: 100%;
    .video-text {
      padding-left: 5px;
      color: #999;
      font-size: 14px;
    }
    ::v-deep .vh-table::before {
      height: 0;
    }
    ::v-deep .vh-card__body {
      padding: 0 0 30px 0;
    }

    // .vh-saas-dialog {

    // }
    .head-operat,
    .no-live {
      margin-bottom: 20px;
      .head-btn {
        display: inline-block;
      }
      ::v-deep .set-upload {
        position: relative;
        cursor: pointer;
        span {
          cursor: pointer;
          input {
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
</style>
<style>
  .tox-tinymce-aux {
    z-index: 3000;
  }
  .tox-fullscreen .tox.tox-tinymce-aux,
  .tox-fullscreen ~ .tox.tox-tinymce-aux {
    z-index: 3000;
  }
</style>
